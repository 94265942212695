import { formatDate, formatTime } from '../../helper/time'

interface DateTimeProps {
  date: Date
  className?: string
}

const DateTime = ({ date, className }: DateTimeProps) => (
  <span className={`flex items-center gap-5 ${className}`}>
    <span className="w-40">{formatTime(date)}</span>
    <span className="w-[90px]">{formatDate(date)}</span>
  </span>
)

export default DateTime
